/* stylelint-disable selector-class-pattern */
import {
  Select as MuiSelect,
} from "@mui/material";
import styled, { css } from "styled-components";
import theme from "styled-theming";

import {
  styleBodyM,
  styleBodyS,
} from "style/components/Typography";

const textColor = theme("theme", {
  undefined: "--color-black",
  lightgray: "--color-black",
  white: "--color-black",
  blue: "--color-cyan-l60",
  cyan: "--color-blue",
});

const borderColor = theme("theme", {
  undefined: "--color-blue-o30",
  lightgray: "--color-blue-o30",
  white: "--color-blue-o30",
  blue: "--color-cyan-l60-o30",
  cyan: "--color-blue-o30",
});

const borderHover = theme("theme", {
  undefined: "--color-cyan-l40",
  lightgray: "--color-cyan-l40",
  white: "--color-cyan-l40",
  blue: "--color-cyan",
  cyan: "--color-cyan",
});

const errorColor = theme("theme", {
  undefined: "--color-coral-d20",
  lightgray: "--color-coral-d20",
  white: "--color-coral-d10",
  blue: "--color-coral",
  cyan: "--color-coral-d25",
});

const borderDisabled = theme("theme", {
  undefined: "--color-black-o15",
  lightgray: "--color-black-o15",
  white: "--color-black-o15",
  blue: "--color-cyan-l60-o20",
  cyan: "--color-blue-o15",
});


export const StyledSelect = styled(MuiSelect)`
  && {
    padding-right: 0;
    width: 100%;

    .MuiOutlinedInput {
      &-notchedOutline {
        border-color: var(${borderColor});
        legend {
          ${(props) => {
            if (props.size === "small")
              return css`
                font-size: 0.7rem;
              `;
            else
              return css`
                font-size: 0.875rem;
              `;
          }}
        }
      }
    }

    &:hover {
      &:not(.Mui-disabled, .Mui-error) {
        .MuiOutlinedInput {
          &-notchedOutline {
            border-color: var(${borderHover});
          }
        }
      }
    }

    &.Mui-error.Mui-focused,
    &.Mui-error {
      .MuiOutlinedInput {
        &-notchedOutline {
          border-color: var(${errorColor});
        }
      }

      .MuiInputBase-input {
        padding-right: 6.375rem !important;
      }
    }

    &.Mui-focused {
      .MuiOutlinedInput {
        &-notchedOutline {
          border-color: var(${borderHover});
          border-width: 2px;
        }
      }
    }

    &.Mui-disabled {
      .MuiOutlinedInput {
        &-notchedOutline {
          border-color: var(${borderDisabled});
        }
      }
    }

    .MuiInputBase {
      &-input {
        ${(props) => {
          if (props.size === "small")
            return css`
              ${styleBodyS}
              padding-top:0.90625rem;
              padding-bottom: 0.90625rem;
            `;
          else
            return css`
              ${styleBodyM}
              height: 2rem;
            `;
        }}
        color: var(${textColor});
      }
    }

    .MuiPaper-root {
      background-color: var(--color-black-l95);
      border: 1px solid var(--color-black-o30);
      border-top: none;
    }
  }
`;
import React, { ReactElement } from "react";

import ListProps from "./ListProps";
import { StyledList } from "./List.styled";

const List = ({ children, ...restProps }: ListProps): ReactElement => {
  return (
    <StyledList {...restProps} $disablePadding={restProps.disablePadding}>
      {children}
    </StyledList>
  );
};

export default React.memo(List);

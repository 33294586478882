import styled, { css } from "styled-components";
import { Drawer } from "@mui/material";
import theme from "styled-theming";
import { drawerClasses } from "@mui/material";
import { MQ } from "style/mediaQueries";
import Link from "components/Link";

const backgroundColor = theme("theme", {
  white: "var(--color-white)",
  lightgray: "var(--color-black-l95)",
});

export const StyledDrawer = styled(Drawer)<StyledDrawerProps>`
  & .${drawerClasses.paper} {
    background-color: ${backgroundColor};
    box-shadow: none;

    ${(props) => {
      if (props.anchor === "left") {
        return `border-right: 2px solid var(--color-black-o15);`;
      } else if (props.anchor === "right") {
        return `border-left: 2px solid var(--color-black-o15);`;
      } else if (props.anchor === "top") {
        return `border-bottom: 2px solid var(--color-black-o15);`;
      } else if (props.anchor === "bottom") {
        return `border-top: 2px solid var(--color-black-o15);`;
      }
    }}

    ${(props) => {
      if (
        props.anchor === "left" ||
        props.anchor === "right" ||
        props.anchor === undefined
      ) {
        switch (props.$size) {
          case "L":
            return css`
              width: 100%;
              ${MQ.FROM_M} {
                width: 50%;
              }
            `;
          case "M":
            return css`
              width: 100%;
              ${MQ.FROM_M} {
                width: 50%;
              }
              ${MQ.FROM_XL} {
                width: calc(100% / 3);
              }
            `;
          case "S":
            return css`
              width: 100%;
              ${MQ.FROM_M} {
                width: 25%;
              }
              ${MQ.FROM_XL} {
                width: 20%;
              }
            `;
          case "XS":
            return css`
              width: 20%;
              ${MQ.FROM_M} {
                width: 15%;
              }
              ${MQ.FROM_XL} {
                width: 10%;
              }
            `;
          default:
            return css``;
        }
      }
    }}
  }
`;

interface StyledDrawerProps {
  $anchor?: "left" | "right" | "top" | "bottom";
  $size?: "XS" | "S" | "M" | "L";
}

export const MenuContainer = styled(Link)<{ $isMobile: boolean }>`
  && {
    color: currentColor;
    width: fit-content;
    ${(props) =>
      props.$isMobile ? "padding: 0.875rem 1rem;" : "padding: 1rem;"}
    :focus-visible {
      outline-offset: -10px;
    }
  }
`;

export const StyledSkipLink = styled(Link)`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  :focus-visible {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
    font-size: 1.5rem;
    margin: 1rem;
  }
`;

export const Spacer = styled.div`
  flex: 1;
`;

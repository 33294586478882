import React, { ReactElement } from "react";

import DividerProps from "./DividerProps";
import { StyledDivider } from "./Divider.styled";

const Divider = ({ children, ...restProps }: DividerProps): ReactElement => {
  return <StyledDivider {...restProps}>{children}</StyledDivider>;
};

export default React.memo(Divider);

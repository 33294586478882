import * as React from "react";
const Insurance28 = ({
  customStyle = {
    verticalAlign: "middle",
  },
  width = "28",
  height = "28",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 28 28"
    style={customStyle}
    {...props}
  >
    <path
      fill="var(--icon-decorative-coral)"
      fillRule="evenodd"
      stroke="var(--icon-decorative-coral)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={0.533}
      d="M14 16.8c.074 0 .133.06.133.133v5.334a3.067 3.067 0 1 0 6.134 0 .133.133 0 0 1 .266 0 3.333 3.333 0 1 1-6.666 0v-5.334c0-.073.06-.133.133-.133"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.2 13.72C3.744 8.261 8.4 4 14 4s10.257 4.261 10.8 9.72a.133.133 0 0 1-.133.147H3.333a.133.133 0 0 1-.132-.147m10.933-.12h10.385c-.591-5.208-5.032-9.266-10.385-9.333zm-.266-9.333V13.6H3.482c.591-5.208 5.032-9.266 10.385-9.333"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="m3.2 13.72-.265-.026zm21.6 0-.266.026zm-.035.103-.197-.18zm-21.53 0-.198.179zm21.283-.223v.267a.266.266 0 0 0 .265-.297zm-10.385 0h-.266c0 .147.12.267.266.267zm0-9.333.004-.266a.267.267 0 0 0-.27.266zm-.266 9.333v.267c.147 0 .266-.12.266-.267zm0-9.333h.266a.267.267 0 0 0-.27-.266zM3.482 13.6l-.265-.03a.267.267 0 0 0 .265.297zM14 3.733c-5.736 0-10.509 4.365-11.065 9.96l.531.053C3.995 8.424 8.538 4.266 14 4.266zm11.065 9.96c-.556-5.595-5.33-9.96-11.065-9.96v.534c5.462 0 10.005 4.157 10.534 9.48zm-.102.309a.4.4 0 0 0 .102-.308l-.531.052a.13.13 0 0 1 .034-.102zm-.296.131a.4.4 0 0 0 .296-.131l-.395-.358a.13.13 0 0 1 .099-.044zm-10.667 0h10.667V13.6H14zm-10.667 0H14V13.6H3.333zm-.296-.131a.4.4 0 0 0 .296.131V13.6c.038 0 .074.016.1.044zm-.102-.308a.4.4 0 0 0 .102.308l.395-.358a.13.13 0 0 1 .034.102zm21.583-.36H14.133v.533h10.385zm-10.388-8.8c5.219.065 9.547 4.021 10.123 9.096l.53-.06c-.607-5.342-5.16-9.501-10.646-9.57zm-.263-.267V13.6h.533V4.267zm.266 9.333V4.267H13.6V13.6zm-.266-.267H3.482v.534h10.385zm-10.12.297C4.323 8.555 8.65 4.6 13.87 4.534l-.007-.533c-5.486.068-10.04 4.227-10.646 9.569z"
    />
  </svg>
);
export default Insurance28;



export const toggleButtonNewsWithoutIcons = {
  items: [
    {
      id: 1,
      displayName: "My news",
      selected: true,
    },
    {
      id: 2,
      displayName: "All news",
      selected: false,
    },
  ],
  className: "newsFilter",
  variant: "pills" as const,
  theme: "white",
};

export const analysisCards = {
  id: "analysisCards",
  theme: "white" as const,
  contentTheme: "black" as const,
  pageCardList: [
    {
      heading: "Osäker omvärld, men nya möjligheter",
      introduction:
        "Efter ett ovanligt starkt januari blev humöret på börserna mer försiktigt under februari. Vi fick se en förnyad oro för högre räntor då de senaste inflationssiffrorna inte motsvarade förhoppningarna. I vår marknadskommentar analyserar vi det vägskäl mellan tillväxt, inflation och pressade marginaler som marknaden står inför.",
      url: "/",
      watermark: "analysis" as const,
      image: {
        decorative: true,
        component: "Image",
        url: "https://picsum.photos/2000/1000",
      },
    },
    {
      heading: "I’m still standing",
      introduction:
        "Aldrig tidigare har en konjunkturell nedgång varit så förutspådd som denna. Än så länge håller dock världsekonomin emot bättre än många väntat sig. Året kommer präglas av en stor osäkerhet, men just nu summeras förutsättningarna för framför allt konjunkturen men även marknaden bäst med låten ”I'm Still Standing”, av Elton John 1983.",
      url: "/",
      watermark: "analysis" as const,
      image: {
        decorative: true,
        component: "Image",
        url: "https://picsum.photos/2000/1000",
      },
    },
    {
      heading: "Turbulensen består",
      introduction:
        "Turbulensen på marknaden som uppkom i samband med SVBs kollaps består. Fokus under förra veckan låg på den finansiella stabiliteten, men även på hanterandet av särskilda obligationer när UBS efter stark uppmaning från den schweiziska centralbanken köpte upp konkurrenten Credit Suisse.",
      url: "/",
      watermark: "analysis" as const,
      image: {
        decorative: true,
        component: "Image",
        url: "https://picsum.photos/2000/1000",
      },
    },
    {
      heading: "Fortsatt återhämtning på aktiemarknaden",
      introduction:
        "November bjöd på en fortsatt stark återhämtning på aktiemarknaden. Marknaden styrs fortsatt av makroekonomisk statistik, och under den gångna månaden fick lägre inflationsutfall och högre tillväxtutfall än väntat marknaden att stiga.",
      url: "/",
      watermark: "analysis" as const,
      image: {
        decorative: true,
        component: "Image",
        url: "https://picsum.photos/2000/1000",
      },
    },
  ],
};


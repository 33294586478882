import styled, { css } from "styled-components";
import theme from "styled-theming";

import { default as NavLink } from "components/Link";
import Watermark from "components/Watermark/";
import { BodyM, HeadingS, LabelM, LabelS } from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

const textColor = theme("theme", {
  default: "--color-black",
  white: "--color-black",
  lightgray: "--color-black",
  blue: "--color-cyan-l60",
  cyan: "--color-blue",
});

export const Container = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  width: 100%;
`;

export const ImageWrapper = styled.div`
  align-items: center;
  aspect-ratio: 1.5;
  background: var(--color-blue-d10);
  color: var(
    ${theme("showImage", {
      true: "--color-white",
      false: "--color-cyan-l60",
    })}
  );
  display: flex;
  flex-direction: column;
  grid-column: col-start 1 / span 4;
  justify-content: center;
  margin-bottom: 0.5rem;
  overflow: hidden;
  position: relative;
  width: 100%;

  figure {
    inset: 0 0 -1px 0; // fix rounding pixels issue
    position: absolute;

    img {
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease-in-out;
      width: 100%;
    }
  }

  ${MQ.FROM_M} {
    margin-bottom: 1rem;
    ${theme("size", {
      large: css`
        grid-column: col-start 1 / span 12;
      `,
    })};
  }

  ${MQ.FROM_L} {
    ${theme("size", {
      large: css`
        grid-column: col-start 1 / span 8;
        margin-bottom: 0;
      `,
    })};
  }
`;

export const Content = styled.div`
  ${columnGap};
  display: grid;
  grid-column: col-start 1 / span 4;
  grid-template-areas:
    "heading heading"
    "introduction introduction"
    "categories categories"
    "publishdate length";
  grid-template-columns: repeat(4, [col-start] 1fr);
  margin-bottom: auto;
  width: 100%;

  ${MQ.FROM_M} {
    ${theme("size", {
      medium: css`
        grid-template-areas:
          "heading heading heading"
          "introduction introduction introduction"
          "categories publishdate length";
        grid-template-columns: repeat(6, [col-start] 1fr);
      `,
      large: css`
        grid-column: col-start 1 / span 12;
        grid-template-areas:
          "heading heading heading"
          "introduction introduction introduction"
          "categories publishdate length";
        grid-template-columns: repeat(12, [col-start] 1fr);
      `,
    })}
  }

  ${MQ.FROM_L} {
    ${theme("size", {
      large: css`
        grid-column: col-start 9 / span 4;
        grid-template-areas:
          "heading heading"
          "introduction introduction"
          "categories categories"
          "publishdate length";
        grid-template-columns: repeat(4, [col-start] 1fr);
      `,
    })};
  }
`;

export const Heading = styled(HeadingS)`
  grid-area: heading;
  grid-column: col-start 1 / span 4;
  margin-bottom: 0.5rem;
  margin-top: 0;

  span {
    border-bottom: 1px solid var(--color-black-o00);
    transition: border-color 0.2s ease-in-out;
  }

  ${MQ.FROM_M} {
    margin-bottom: 1rem;

    ${theme("size", {
      medium: css`
        grid-column: col-start 1 / span 6;
      `,
      large: css`
        grid-column: col-start 1 / span 12;
      `,
    })};
  }

  ${MQ.FROM_L} {
    ${theme("size", {
      large: css`
        grid-column: col-start 1 / span 4;
      `,
    })};
  }
`;

export const Introduction = styled(BodyM)`
  grid-area: introduction;
  grid-column: col-start 1 / span 4;

  ${MQ.FROM_M} {
    ${theme("size", {
      medium: css`
        grid-column: col-start 1 / span 6;
      `,
      large: css`
        grid-column: col-start 1 / span 12;
      `,
    })};
  }

  ${MQ.FROM_L} {
    ${theme("size", {
      large: css`
        grid-column: col-start 1 / span 4;
      `,
    })};
  }
`;

export const Categories = styled(LabelM)`
  grid-area: categories;
  grid-column: col-start 1 / span 4;

  ${MQ.FROM_M} {
    ${theme("size", {
      medium: css`
        grid-column: col-start 1 / span 3;
      `,
      large: css`
        grid-column: col-start 1 / span 4;
      `,
    })};
  }

  ${MQ.FROM_L} {
    ${theme("size", {
      large: css`
        grid-column: col-start 1 / span 4;
      `,
    })};
  }

  ${MQ.FROM_XL} {
    ${theme("size", {
      medium: css`
        grid-column: col-start 1 / span 2;
      `,
      large: css`
        grid-column: col-start 1 / span 4;
      `,
    })};
  }
`;

export const PublishDate = styled(LabelS)`
  grid-area: publishdate;
  grid-column: col-start 1 / span 2;

  ${MQ.FROM_M} {
    ${theme("size", {
      medium: css`
        grid-column: col-start 4 / span 2;
      `,
      large: css`
        grid-column: col-start 5 / span 4;
      `,
    })};
  }

  ${MQ.FROM_L} {
    ${theme("size", {
      large: css`
        grid-column: col-start 1 / span 2;
      `,
    })};
  }

  ${MQ.FROM_XL} {
    ${theme("size", {
      medium: css`
        grid-column: col-start 3 / span 2;
      `,
    })};
  }
`;

export const Length = styled(LabelS)`
  grid-area: length;
  grid-column: col-start 3 / span 2;
  text-align: right;

  svg {
    vertical-align: -0.8em;
  }

  ${MQ.FROM_M} {
    ${theme("size", {
      medium: css`
        grid-column: col-start 5 / span 2;
      `,
      large: css`
        grid-column: col-start 9 / span 4;
      `,
    })};
  }

  ${MQ.FROM_L} {
    ${theme("size", {
      large: css`
        grid-column: col-start 3 / span 2;
      `,
    })};
  }
`;

export const StyledWatermark = styled(Watermark)`
  pointer-events: none;
  z-index: 2;
`;

export const Link = styled(NavLink)`
  && {
    ${columnGap};
    border-bottom: none;
    color: var(${textColor});
    display: grid;
    grid-template-columns: repeat(4, [col-start] 1fr);
    width: 100%;

    &:focus-visible {
      outline: 7px solid var(--color-cyan-o20);
      outline-offset: 0;
    }

    &:hover {
      ${Heading} {
        span {
          border-color: var(--color-black);
        }
      }

      ${ImageWrapper} {
        img {
          ${theme("isSafari", {
            false: css`
              transform: scale(1.04);
            `,
          })};
        }
      }
    }

    ${MQ.FROM_M} {
      ${theme("size", {
        large: css`
          grid-template-columns: repeat(12, [col-start] 1fr);
        `,
      })};
    }
  }
`;

export const ContentContainer = styled.div`
  && {
    ${columnGap};
    border-bottom: none;
    display: grid;
    grid-template-columns: repeat(4, [col-start] 1fr);
    width: 100%;

    &:hover {
      ${Heading} {
        span {
          border-color: var(--color-black);
        }
      }

      ${ImageWrapper} {
        img {
          ${theme("isSafari", {
            false: css`
              transform: scale(1.04);
            `,
          })};
        }
      }
    }

    ${MQ.FROM_M} {
      ${theme("size", {
        large: css`
          grid-template-columns: repeat(12, [col-start] 1fr);
        `,
      })};
    }
  }
`;

import * as React from "react";
const Hr28 = ({
  customStyle = {
    verticalAlign: "middle",
  },
  width = "28",
  height = "28",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 28 28"
    style={customStyle}
    {...props}
  >
    <path
      fill="var(--icon-decorative-coral)"
      fillRule="evenodd"
      d="M20.015 20.084a.133.133 0 0 1 .157-.104c1.452.287 2.667.68 3.522 1.145.844.46 1.385 1.02 1.385 1.658 0 .507-.342.963-.894 1.355-.557.394-1.353.744-2.325 1.036-1.946.584-4.626.943-7.581.943s-5.635-.36-7.58-.943c-.973-.292-1.77-.642-2.326-1.036-.552-.392-.894-.848-.894-1.355 0-.636.537-1.193 1.376-1.652.85-.465 2.056-.857 3.5-1.145a.133.133 0 1 1 .052.261c-1.43.286-2.608.67-3.424 1.118-.827.452-1.237.942-1.237 1.418 0 .378.255.764.782 1.137.523.371 1.289.711 2.247.998 1.914.575 4.568.932 7.504.932s5.59-.357 7.504-.932c.958-.287 1.724-.627 2.247-.998.527-.373.782-.76.782-1.137 0-.478-.413-.97-1.246-1.424-.822-.448-2.007-.833-3.446-1.118a.133.133 0 0 1-.105-.157"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.746 5.183a2.534 2.534 0 1 1 5.067.001 2.534 2.534 0 0 1-5.067 0m2.533-2.8a2.8 2.8 0 1 0 0 5.6 2.8 2.8 0 0 0 0-5.6M9.612 12.65a2 2 0 0 1 2-2h5.334a2 2 0 0 1 2 2v4.163l-2.033.508a.13.13 0 0 0-.1.116l-.521 5.213h-4.026l-.521-5.213a.13.13 0 0 0-.1-.116l-2.033-.508zm2-2.267a2.267 2.267 0 0 0-2.266 2.267v4.267c0 .06.041.114.1.129l2.043.51.524 5.24c.007.069.064.12.133.12h4.266a.133.133 0 0 0 .133-.12l.524-5.24 2.042-.51c.06-.015.101-.068.101-.13V12.65a2.267 2.267 0 0 0-2.266-2.267z"
      clipRule="evenodd"
    />
  </svg>
);
export default Hr28;

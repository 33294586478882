import * as React from "react";
const Globe28 = ({
  customStyle = {
    verticalAlign: "middle",
  },
  width = "28",
  height = "28",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 28 28"
    style={customStyle}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.05 2.281a.5.5 0 0 1 .67-.23C18.836 4.057 21 8.466 21 13.5c0 5.033-2.163 9.442-6.28 11.449a.5.5 0 0 1-.439-.9c3.682-1.793 5.72-5.784 5.72-10.55s-2.038-8.757-5.72-10.55a.5.5 0 0 1-.23-.669"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.95 2.281a.5.5 0 0 1-.231.669C11.037 4.743 9 8.734 9 13.5s2.037 8.757 5.719 10.55a.5.5 0 0 1-.438.9C10.163 22.943 8 18.534 8 13.5s2.163-9.443 6.281-11.45a.5.5 0 0 1 .668.231"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.5 2a.5.5 0 0 1 .5.5v22a.5.5 0 0 1-1 0v-22a.5.5 0 0 1 .5-.5"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 13.5a.5.5 0 0 1 .5-.5h22a.5.5 0 0 1 0 1h-22a.5.5 0 0 1-.5-.5"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.5 3C8.701 3 4 7.701 4 13.5S8.701 24 14.5 24 25 19.299 25 13.5 20.299 3 14.5 3M3 13.5C3 7.149 8.149 2 14.5 2S26 7.149 26 13.5 20.851 25 14.5 25 3 19.851 3 13.5"
      clipRule="evenodd"
    />
  </svg>
);
export default Globe28;

import React, { ReactElement, useContext } from "react";

import {
  CardContainer,
  CardIcon,
  CardMainBody,
  CardTitle,
  CardDescription,
  CardFiguresContainer,
  CardTopRightDescription,
  CardText,
  SkeletonContainer,
  CardSkeletonContainer,
} from "./ProductCardItem.styled";
import ProductCardItemProps from "./ProductCardItemProps";
import { ThemeContext, ThemeProvider } from "styled-components";
import { useBreakpoint } from "hooks";
import Skeleton from "components/Skeleton";
import Icon from "components/Icon";

const ProductCardItem = ({
  icon,
  title,
  description,
  topRightDescription,
  theme,
  rightDescription,
  loading,
  contentId,
  className,
  anchorName,
}: ProductCardItemProps): ReactElement => {
  const exitingTheme = useContext(ThemeContext) || {};
  const breakpoint = useBreakpoint();

  if (typeof icon === "string") {
    switch (breakpoint) {
      case "XL":
      case "XXL":
      case "XXXL":
      case "XXXXL":
        icon = `${icon}140`;
        break;
      case "L":
      case "M":
        icon = `${icon}105`;
        break;
      case "S":
        icon = `${icon}56`;
        break;
      default:
        icon = `${icon}28`;
    }
  }

  return (
    <ThemeProvider
      theme={{
        theme: theme || exitingTheme["theme"],
      }}
    >
      <CardContainer
        className={className || "CardContainer"}
        id={contentId || anchorName}
      >
        <CardMainBody className="CardMainBody">
          {loading ? (
            <SkeletonContainer>
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
              ></Skeleton>
            </SkeletonContainer>
          ) : (
            icon && (
              <CardIcon>
                {typeof icon === "string" ? (
                  <Icon icon={icon} aria-hidden="true"></Icon>
                ) : (
                  (icon as React.ReactElement)
                )}
              </CardIcon>
            )
          )}

          <CardText className="CardText" hasIcon={!!icon}>
            {loading ? (
              <Skeleton
                variant="text"
                width={breakpoint === "S" ? "110px" : "150px"}
              >
                <CardTitle>title</CardTitle>
              </Skeleton>
            ) : (
              title && (
                <CardTitle
                  isString={typeof title === "string"}
                  className="CardTitle"
                >
                  {title}
                </CardTitle>
              )
            )}
            {loading ? (
              <Skeleton
                variant="text"
                width={breakpoint === "S" ? "130px" : "200px"}
              >
                <CardDescription>description</CardDescription>
              </Skeleton>
            ) : (
              description && <CardDescription>{description}</CardDescription>
            )}
          </CardText>
        </CardMainBody>
        {(topRightDescription || rightDescription || loading) && (
          <CardFiguresContainer>
            {loading ? (
              <CardSkeletonContainer>
                <Skeleton variant="text" width={"130px"}>
                  <CardTopRightDescription>figure</CardTopRightDescription>
                </Skeleton>
              </CardSkeletonContainer>
            ) : (
              topRightDescription && (
                <CardTopRightDescription
                  isString={typeof topRightDescription === "string"}
                >
                  {topRightDescription}
                </CardTopRightDescription>
              )
            )}
            {loading ? (
              <CardSkeletonContainer>
                <Skeleton variant="text" width={"80px"}>
                  <CardDescription>rightDescriptionk</CardDescription>
                </Skeleton>
              </CardSkeletonContainer>
            ) : (
              rightDescription && (
                <CardDescription>{rightDescription}</CardDescription>
              )
            )}
          </CardFiguresContainer>
        )}
      </CardContainer>
    </ThemeProvider>
  );
};

export default React.memo(ProductCardItem);

import * as React from "react";
const Pension28 = ({
  customStyle = {
    verticalAlign: "middle",
  },
  width = "28",
  height = "28",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 28 28"
    style={customStyle}
    {...props}
  >
    <path
      fill="var(--color-coral)"
      fillRule="evenodd"
      d="M8.883 6.371a4.441 4.441 0 1 1 8.882 0 4.441 4.441 0 0 1-8.882 0m4.441-4.828a4.829 4.829 0 1 0 .001 9.657 4.829 4.829 0 0 0 0-9.657m.192 3.285a.195.195 0 0 0-.192-.195.195.195 0 0 0-.191.195v3.09a.192.192 0 0 0 .383 0Zm0 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.613 13.273a5.99 5.99 0 0 0-6.25.825 5.98 5.98 0 0 0 3.782 1.351 5.94 5.94 0 0 0 3.785-1.36 6 6 0 0 0-1.317-.816m1.614 1.075a5.987 5.987 0 0 1 1.902 4.191H8.69a.2.2 0 0 0-.144.063.19.19 0 0 0-.047.152l.77 6.18a.19.19 0 0 0 .19.168h7.727a.19.19 0 0 0 .192-.168l.77-6.18a.19.19 0 0 0-.047-.152.2.2 0 0 0-.145-.063H13.52a5.99 5.99 0 0 1 1.902-4.191 6.33 6.33 0 0 0 4.082 1.488c1.55 0 3.043-.566 4.203-1.594a.2.2 0 0 0 .066-.144.19.19 0 0 0-.066-.145 6.36 6.36 0 0 0-6.832-1.031 6.4 6.4 0 0 0-2.727 2.351 6.3 6.3 0 0 0-.824 1.895 6.3 6.3 0 0 0-.824-1.895 6.4 6.4 0 0 0-2.727-2.351 6.37 6.37 0 0 0-6.835 1.031.2.2 0 0 0 0 .29 6.344 6.344 0 0 0 8.289.105m2.093 4.578h4.418l-.722 5.793H9.633l-.723-5.793Zm2.399-4.836a5.94 5.94 0 0 0 3.785 1.36c1.379 0 2.715-.481 3.777-1.352a5.98 5.98 0 0 0-6.246-.824 6 6 0 0 0-1.316.816m0 0"
      clipRule="evenodd"
    />
  </svg>
);
export default Pension28;

import styled from "styled-components";
import { ListItemButton } from "@mui/material";

export const StyledListItemButton = styled(ListItemButton)`
  &.MuiListItemButton-root {
    gap: 0.4375rem;
    padding-left: 0;
    padding-right: 0;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 0.25rem;
  }
`;

import * as React from "react";
const LogoSmallDefault42 = ({
  customStyle = {
    verticalAlign: "middle",
  },
  width = "34",
  height = "42",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 34 42"
    style={customStyle}
    {...props}
  >
    <path
      fill="#009FDF"
      d="M18.754 7.825c-.51 1.745-.67 3.174-.91 5.55-.001.012-.041.284.252.342q.31.043.399-.249c1.584-5.474 5.532-8.428 7.616-9.31.15-.07.217-.194.185-.34-.048-.216-.314-.448-.661-.448a.8.8 0 0 0-.235.036l-.01.002v-.01c-.02-.685-.822-1.483-1.826-1.483q-.367 0-.738.134l-.017.006.007-.017c.089-.193.005-.361-.124-.427a.2.2 0 0 0-.105-.023c-.175 0-.444.155-.776.45-1.102.977-2.33 3.303-3.057 5.787"
    />
    <path
      fill="#99D9F2"
      d="M31.453 5.5a8.4 8.4 0 0 0-1.977-.24c-4.032 0-6.762 2.993-7.474 4.145-.002.002-.12.171-.07.296q.029.088.172.077l.18-.03c.519-.083 1.389-.223 2.436-.223a11 11 0 0 1 1.542.105c1.369.19 4.066.906 5.495 2.376.087.088.189.086.276-.007.124-.133.192-.412.172-.71-.045-.687-.296-.908-.548-1.078l-.012-.007.012-.006c.324-.148.553-.715.557-1.378.004-.584-.175-1.294-.688-1.538l-.015-.008.015-.006c.381-.165.659-.641.62-1.063-.033-.36-.28-.61-.693-.705"
    />
    <path
      fill="#009FDF"
      d="M25.94 36.76c.147 0 .268-.056.333-.154.118-.18.08-.397-.092-.537-1.825-1.541-3-2.574-4.077-4.32l-.206-.332c-.989-1.59-1.923-3.09-3.373-8.34-.093-.247-.26-.284-.35-.284a.4.4 0 0 0-.08.01c-.011.002-.291.066-.249.431.017.153.038.4.063.714.141 1.694.433 5.219 1.247 7.985.967 3.284 1.637 4.879 2.988 7.112.086.148.21.228.35.228a.37.37 0 0 0 .286-.137.33.33 0 0 0 .07-.283l-.003-.01.01.001a2.7 2.7 0 0 0 2.314-.754c.388-.394.608-.885.601-1.345v-.01l.01.002q.077.023.159.023"
    />
    <path
      fill="#99D9F2"
      d="M28.91 30.03c-4.97-.806-6.676-3.753-8.047-6.12-.24-.416-.468-.808-.7-1.167-.002-.002-.124-.184-.3-.184a.3.3 0 0 0-.106.022c-.01.002-.23.056-.162.427l.018.085c.516 2.511 1.559 4.94 3.099 7.218 1.19 1.634 2.416 3.197 4.331 4.34.223.116.457.058.558-.125a.35.35 0 0 0-.01-.36l-.005-.007.008-.003c1.053-.517 1.88-1.746 1.42-3.098l-.004-.01h.01c.19-.011.374-.228.394-.464.011-.129-.016-.44-.505-.553"
    />
    <path
      fill="#009FDF"
      d="M28.834 28.478q.151 0 .305-.008c.268-.03.473-.194.534-.428a.57.57 0 0 0-.238-.615l-.005-.004.004-.006c.52-.762.492-1.43.377-1.857-.15-.555-.538-1.04-1.012-1.265l-.007-.004.004-.007a.9.9 0 0 0 .003-.906.87.87 0 0 0-.738-.432.9.9 0 0 0-.291.053c-.967.28-1.695.452-2.339.452-1.006 0-1.82-.409-3.437-1.341a.9.9 0 0 0-.385-.118q-.164 0-.274.137c-.143.176.016.53.017.533.026.06 2.69 5.816 7.482 5.816M31.936 14.569q.001-.001.014.004c.027.01.1.038.172.038a.18.18 0 0 0 .121-.04.294.294 0 0 0 .053-.387c-.23-.355-.497-.67-1.18-1.226-2.047-1.667-4.963-2.26-7.008-2.26-1.59 0-2.843.344-3.353.92-.785.974-1.232 2.392-1.299 3.089l-.002.023-.014.156v.022c-.003.056-.008.158.05.22q.091.095.337.02l.055-.019c1.302-.5 2.66-.754 4.037-.754 3.238 0 6.016 1.4 7.41 2.6.003.002.237.223.445.223a.24.24 0 0 0 .151-.05s.07-.052.08-.143q.012-.109-.092-.228l-.005-.007.007-.004c.334-.215.428-.589.448-.865.036-.504-.145-1.086-.427-1.332"
    />
    <path
      fill="#002D72"
      d="M31.97 21.513c.13-.358.042-.804-.263-1.326l-.014-.023.024.012q.039.02.081.02c.05 0 .104-.021.145-.058.087-.077.048-.228.014-.361-.036-.144-.124-.316-.39-.768-1.006-1.712-4.444-3.546-7.955-3.546-1.084 0-2.1.173-3.018.516-.832.31-1.126.594-1.196.674q-.154.174-.234.554c-.17.757-.052 3.191.19 3.945 0 .001.053.152.177.173.142.024.23-.1.23-.101 1.403-1.787 2.983-2.692 4.698-2.692 2.756 0 5.234 2.345 6.465 3.744.152.163.252.233.333.233a.23.23 0 0 0 .114-.039.24.24 0 0 0 .107-.154c.02-.119-.062-.233-.062-.235l-.006-.008.01-.004c.003 0 .4-.145.55-.556"
    />
    <path
      fill="#009FDF"
      d="M8.828 1.915c-1.004 0-1.807.798-1.827 1.483v.01l-.01-.002a.8.8 0 0 0-.234-.035c-.347 0-.613.232-.661.447-.032.146.035.27.185.34 2.083.882 6.032 3.836 7.616 9.31.06.196.187.278.399.25.292-.059.252-.331.25-.343-.24-2.376-.4-3.805-.91-5.55-.726-2.484-1.954-4.81-3.055-5.786-.407-.361-.717-.512-.88-.428-.13.067-.214.235-.125.428l.007.017-.017-.006a2.2 2.2 0 0 0-.738-.135"
    />
    <path
      fill="#99D9F2"
      d="M10.395 9.406c-.712-1.153-3.442-4.145-7.474-4.145-.652 0-1.317.08-1.977.239-.414.095-.66.345-.694.706-.039.421.24.898.62 1.063l.015.006-.015.008c-.514.244-.692.954-.688 1.537.004.664.233 1.231.557 1.38l.012.005-.011.007c-.253.17-.504.391-.55 1.078-.018.298.049.577.174.71.086.093.188.095.275.007 1.429-1.47 4.126-2.185 5.495-2.376.5-.07 1.019-.105 1.543-.105 1.047 0 1.917.14 2.436.224l.18.029c.092.007.152-.018.17-.076.051-.127-.066-.295-.068-.297"
    />
    <path
      fill="#009FDF"
      d="M9.614 39.135c.07.087.177.138.286.138.139 0 .264-.08.35-.228 1.351-2.234 2.02-3.83 2.987-7.113.814-2.766 1.107-6.29 1.247-7.985.026-.314.046-.56.063-.714.042-.365-.238-.43-.249-.431a.4.4 0 0 0-.08-.01c-.09 0-.257.038-.35.286-1.45 5.248-2.384 6.749-3.373 8.338l-.205.332c-1.078 1.746-2.252 2.78-4.077 4.32-.172.14-.21.357-.092.537.065.099.186.155.332.155a.6.6 0 0 0 .158-.023l.01-.003v.01c-.006.46.213.95.602 1.345.589.597 1.464.886 2.313.754l.01-.002-.002.011a.33.33 0 0 0 .07.283"
    />
    <path
      fill="#99D9F2"
      d="M5.352 34.65c1.916-1.142 3.142-2.705 4.332-4.34 1.54-2.277 2.582-4.706 3.099-7.217l.018-.086c.067-.371-.152-.424-.161-.427a.3.3 0 0 0-.107-.022c-.177 0-.298.182-.3.184-.232.36-.46.752-.7 1.167-1.372 2.368-3.078 5.314-8.047 6.12-.488.114-.516.425-.505.554.02.236.204.453.394.464h.01l-.004.011c-.46 1.352.367 2.582 1.42 3.098l.008.004-.004.007a.35.35 0 0 0-.01.36c.1.183.334.24.557.123"
    />
    <path
      fill="#009FDF"
      d="M11.041 22.663c.002-.003.16-.357.018-.532-.142-.179-.339-.183-.66-.02-1.616.933-2.43 1.341-3.437 1.341-.643 0-1.372-.173-2.338-.452a.9.9 0 0 0-.292-.053.87.87 0 0 0-.737.432.9.9 0 0 0 .002.906l.004.007-.007.003c-.474.226-.862.711-1.011 1.266-.115.426-.143 1.095.377 1.857l.004.006-.006.004a.57.57 0 0 0-.238.615c.06.234.266.398.535.428q.151.008.305.008c4.792 0 7.455-5.757 7.481-5.816"
    />
    <path
      fill="#002D72"
      d="M16.198 13.69q.446-.001.65-.22c.151-.16.14-.357.14-.359.198-3.84.625-5.94 1.758-8.642.397-.946.678-1.435 1.59-2.881a.24.24 0 0 0 .028-.203c-.042-.138-.2-.27-.455-.38a1.3 1.3 0 0 0-.521-.09c-.378 0-.785.098-1.149.275l-.007.004-.003-.008C17.82.033 16.392 0 16.231 0h-.069c-.161 0-1.588.033-1.998 1.186l-.003.008-.008-.004a2.7 2.7 0 0 0-1.148-.275c-.209 0-.39.03-.522.09-.254.11-.412.242-.455.38a.24.24 0 0 0 .03.203c.91 1.446 1.192 1.935 1.59 2.881 1.132 2.701 1.559 4.801 1.756 8.642 0 .002-.01.198.14.36q.204.218.65.219l.003.008v-.008M19.159 40.808c.483 0 .951-.153 1.284-.418.367-.335.1-.763.096-.767-3.178-5.777-3.452-13.568-3.54-16.128l-.002-.035c-.016-.472-.285-.712-.798-.712-.516 0-.784.24-.8.712l-.001.035c-.09 2.56-.363 10.35-3.542 16.128-.002.004-.27.433.098.767.332.265.8.418 1.283.418.219 0 .418-.032.577-.094l.008-.003.002.008c.303 1.22 1.961 1.282 2.29 1.282q.076 0 .084-.002.008.001.085.002c.329 0 1.987-.062 2.29-1.282l.002-.008.008.003c.158.062.357.094.576.094"
    />
    <path
      fill="#009FDF"
      d="M.471 17.149q.064.049.151.05c.208 0 .443-.221.445-.223 1.395-1.2 4.173-2.6 7.411-2.6 1.377 0 2.735.253 4.036.754 0 0 .022.009.056.02.162.048.277.04.336-.022s.053-.164.05-.22v-.022l-.014-.155-.002-.024c-.067-.696-.514-2.114-1.299-3.088-.51-.577-1.764-.92-3.353-.92-2.045 0-4.961.593-7.008 2.26-.683.556-.95.87-1.18 1.226a.295.295 0 0 0 .053.387q.045.04.121.04a.5.5 0 0 0 .186-.043c-.282.247-.463.828-.426 1.334.02.276.115.65.448.864l.007.005-.007.006q-.102.12-.09.228c.01.09.078.142.079.142"
    />
    <path
      fill="#002D72"
      d="M1.02 22.471a.23.23 0 0 0 .113.039c.08 0 .181-.07.333-.234 1.232-1.398 3.71-3.744 6.465-3.744 1.715 0 3.296.906 4.698 2.692.001.002.089.125.23.101.124-.02.178-.171.178-.172.242-.755.36-3.188.19-3.945-.053-.252-.132-.44-.234-.555-.07-.08-.364-.364-1.196-.674-.919-.342-1.934-.515-3.018-.515-3.512 0-6.949 1.834-7.956 3.546-.266.452-.353.624-.39.768-.034.133-.072.283.015.36.066.059.156.076.226.038l.023-.012-.013.023c-.305.522-.393.968-.262 1.326.15.411.546.556.55.557l.01.004-.007.008s-.083.116-.062.234a.24.24 0 0 0 .107.155"
    />
  </svg>
);
export default LogoSmallDefault42;

import { css } from "styled-components";

export const colorSystem = css`
  --background-default: var(--color-black-l95);
  --block-black-default: var(--color-black);
  --block-blue-default: var(--color-blue-d10);
  --block-coral-default: var(--color-coral);
  --block-cyan-default: var(--color-cyan-l80);
  --block-neutral-default: var(--color-black-l95);
  --block-white-default: var(--color-white);
  --button-focus: var(--color-cyan-o20);
  --button-on-blue-disabled: var(--color-cyan-l60-o30);
  --button-on-blue-outline-alt-border: var(--color-coral-o40);
  --button-on-blue-outline-alt-hover: var(--color-coral-l10);
  --button-on-blue-outline-border: var(--color-cyan-l60-o30);
  --button-on-blue-outline-hover: var(--color-cyan-l70);
  --button-on-blue-primary: var(--color-cyan-l60);
  --button-on-blue-primary-alt: var(--color-coral);
  --button-on-blue-primary-alt-hover: var(--color-coral-l10);
  --button-on-blue-primary-hover: var(--color-cyan-l70);
  --button-on-cyan-disabled: var(--color-blue-o15);
  --button-on-cyan-outline-border: var(--color-blue-o15);
  --button-on-cyan-outline-hover: var(--color-blue-l05);
  --button-on-cyan-primary: var(--color-blue);
  --button-on-cyan-primary-hover: var(--color-blue-l05);
  --button-on-image-blur: blur(1.5px);
  --button-on-image-disabled: var(--color-white-o50);
  --button-on-image-outline: var(--color-black-o05);
  --button-on-image-outline-border: var(--color-white-o50);
  --button-on-image-outline-hover: var(--color-white);
  --button-on-image-primary: var(--color-white-o90);
  --button-on-image-primary-hover: var(--color-white);
  --button-on-neutral-disabled: var(--color-black-o15);
  --button-on-neutral-outline-alt-border: var(--color-coral-o40);
  --button-on-neutral-outline-alt-hover: var(--color-coral-l10);
  --button-on-neutral-outline-border: var(--color-black-o15);
  --button-on-neutral-outline-hover: var(--color-blue-l05);
  --button-on-neutral-primary: var(--color-blue);
  --button-on-neutral-primary-alt1: var(--color-coral);
  --button-on-neutral-primary-alt1-hover: var(--color-coral-l10);
  --button-on-neutral-primary-alt2: var(--color-cyan-l60);
  --button-on-neutral-primary-alt2-hover: var(--color-cyan-l70);
  --button-on-neutral-primary-hover: var(--color-blue-l05);
  --field-focus: var(--color-cyan-o20);
  --field-on-blue-default-border: var(--color-cyan-l60-o30);
  --field-on-blue-disabled-border: var(--color-cyan-l60-o20);
  --field-on-blue-error-border: var(--color-coral);
  --field-on-blue-hover-border: var(--color-cyan);
  --field-on-blue-verified-border: var(--color-cyan-l60-o20);
  --field-on-cyan-default-border: var(--color-blue-o30);
  --field-on-cyan-disabled-border: var(--color-blue-o15);
  --field-on-cyan-error-border: var(--color-coral-d25);
  --field-on-cyan-hover-border: var(--color-cyan);
  --field-on-cyan-verified-border: var(--color-blue-o15);
  --field-on-neutral-default-border: var(--color-blue-o30);
  --field-on-neutral-disabled-border: var(--color-black-o15);
  --field-on-neutral-error-border: var(--color-coral-d20);
  --field-on-neutral-hover-border: var(--color-cyan-l40);
  --field-on-neutral-verified-border: var(--color-blue-o15);
  --filter-focus: var(--color-cyan-o20);
  --filter-on-blue-default-border: var(--color-cyan-l60-o30);
  --filter-on-blue-disabled-border: var(--color-cyan-l60-o20);
  --filter-on-blue-hover-border: var(--color-cyan-l60-o61);
  --filter-on-blue-selected: var(--color-cyan-l60);
  --filter-on-cyan-default-border: var(--color-blue-o15);
  --filter-on-cyan-disabled-border: var(--color-blue-o15);
  --filter-on-cyan-hover-border: var(--color-blue-o69);
  --filter-on-cyan-selected: var(--color-blue);
  --filter-on-neutral-default-border: var(--color-black-o15);
  --filter-on-neutral-disabled-border: var(--color-black-o15);
  --filter-on-neutral-hover-border: var(--color-black-o68);
  --filter-on-neutral-selected: var(--color-blue);
  --gradient-background-default-opacity: linear-gradient(
    to right,
    var(--color-black-l95-o00),
    var(--color-black-l95)
  );
  --gradient-upleft: linear-gradient(
      to bottom right,
      var(--color-blue-o00) 0%,
      var(--color-blue-o40) 100%
    ),
    linear-gradient(
      to top left,
      var(--color-coral-o00) 0%,
      var(--color-coral-o30) 100%
    );
  --gradient-upright: linear-gradient(
      to bottom left,
      var(--color-blue-o00) 0%,
      var(--color-blue-o40) 100%
    ),
    linear-gradient(
      to top right,
      var(--color-coral-o00) 0%,
      var(--color-coral-o30) 100%
    );
  --icon-decorative-blue: var(--color-blue);
  --icon-decorative-coral: var(--color-coral);
  --icon-decorative-cyan: var(--color-cyan-l60);
  --icon-on-blue-disabled: var(--color-cyan-l60-o30);
  --icon-on-blue-primary: var(--color-cyan-l60);
  --icon-on-blue-primary-alt1: var(--color-cyan);
  --icon-on-blue-primary-alt2: var(--color-coral);
  --icon-on-blue-primary-alt3: var(--color-white);
  --icon-on-cyan-disabled: var(--color-blue-o30);
  --icon-on-cyan-primary: var(--color-blue);
  --icon-on-cyan-primary-alt1: var(--color-cyan-d25);
  --icon-on-cyan-primary-alt2: var(--color-coral-d25);
  --icon-on-image-primary: var(--color-white);
  --icon-on-neutral-disabled: var(--color-black-o30);
  --icon-on-neutral-primary: var(--color-black);
  --icon-on-neutral-primary-alt1: var(--color-cyan-d20);
  --icon-on-neutral-primary-alt2: var(--color-coral-d20);
  --icon-on-neutral-primary-hover: var(--color-cyan-d20);
  --image-darken: rgb(0 0 0 / 40%);
  --label-on-blue-error: var(--color-coral);
  --label-on-cyan-error: var(--color-coral-d25);
  --label-on-neutral-error: var(--color-coral-d20);
  --line-on-blue-default: var(--color-cyan-l60-o30);
  --line-on-cyan-default: var(--color-blue-o15);
  --line-on-image-default: var(--color-white-o30);
  --line-on-neutral-default: var(--color-black-o15);
  --modal-shadow: 0px 0px 28px 0px rgba(0 0 0 / 25%);
  --overlay-neutral-darken: var(--color-black-o05);
  --overlay-neutral-lighten: var(--color-white);
  --overlay-on-blue-darken: var(--color-black-o30);
  --overlay-on-blue-lighten: var(--color-cyan-o20);
  --overlay-on-cyan-darken: var(--color-blue-o10);
  --shadow-on-image: drop-shadow(0px 0px 28px #000);
  --text-inverted: var(--color-white);
  --text-inverted-link-border: var(--color-white-o30);
  --text-inverted-secondary: var(--color-white-o50);
  --text-on-blue-disabled: var(--color-cyan-l60-o30);
  --text-on-blue-link-border: var(--color-cyan-l60-o30);
  --text-on-blue-primary: var(--color-cyan-l60);
  --text-on-blue-primary-alt: var(--color-coral);
  --text-on-blue-secondary: var(--color-cyan-l60-o61);
  --text-on-coral-link-border: var(--color-blue-o30);
  --text-on-coral-primary: var(--color-blue);
  --text-on-cyan-disabled: var(--color-blue-o30);
  --text-on-cyan-link-border: var(--color-blue-o30);
  --text-on-cyan-primary: var(--color-blue);
  --text-on-cyan-secondary: var(--color-blue-o69);
  --text-on-image-disabled: var(--color-white-o50);
  --text-on-image-primary: var(--color-white);
  --text-on-neutral-disabled: var(--color-black-o30);
  --text-on-neutral-inverted: var(--color-white);
  --text-on-neutral-link: var(--color-cyan-d20);
  --text-on-neutral-link-alt: var(--color-black);
  --text-on-neutral-link-alt-border: var(--color-black-o30);
  --text-on-neutral-link-border: var(--color-cyan-d20-o30);
  --text-on-neutral-primary: var(--color-black);
  --text-on-neutral-primary-alt1: var(--color-blue);
  --text-on-neutral-primary-alt2: var(--color-coral-d20);
  --text-on-neutral-secondary: var(--color-black-o68);

  // Custom colors
  --text-red-on-neutral: var(--color-red);
  --text-green-on-neutral: var(--color-green);
  --text-red-on-blue: var(--color-red-light);
  --text-green-on-blue: var(--color-green-light);
`;

export const colors = css`
  ${colorSystem};
  --color-black: #332e30;
  --color-black-l40: #858081;
  --color-black-l50: #858081;
  --color-black-l90: #ebeaea;
  --color-black-l95: #f5f3f3;
  --color-black-l95-o00: rgba(245 243 243 / 0%);
  --color-black-l95-o98: rgba(245 243 243 / 98%);
  --color-black-o00: rgba(51 43 46 / 0%);
  --color-black-o05: rgba(51 43 46 / 5%);
  --color-black-o10: rgba(51 43 46 / 10%);
  --color-black-o15: rgba(51 43 46 / 15%);
  --color-black-o20: rgba(51 43 46 / 20%);
  --color-black-o30: rgba(51 43 46 / 30%);
  --color-black-o40: rgba(51 43 46 / 40%);
  --color-black-o50: rgba(51 43 46 / 50%);
  --color-black-o60: rgba(51 43 46 / 60%);
  --color-black-o68: rgba(51 46 48 / 68%);
  --color-blue: #002d72;
  --color-blue-d10: #002359;
  --color-blue-l05: #0d3a80;
  --color-light-blue: #B8D9EB;
  --color-blue-o00: rgba(0 45 114 / 0%);
  --color-blue-o10: rgba(0 45 114 / 10%);
  --color-blue-o15: rgba(0 45 114 / 15%);
  --color-blue-o20: rgba(0 45 114 / 20%);
  --color-blue-o30: rgba(0 45 114 / 30%);
  --color-blue-o40: rgba(0 45 114 / 40%);
  --color-blue-o50: rgba(0 45 114 / 50%);
  --color-blue-o60: rgba(0 45 114 / 60%);
  --color-blue-o69: rgba(0 45 114 / 69%);
  --color-coral: #ff6380;
  --color-coral-d05: #ff5473;
  --color-coral-d10: #ee4363;
  --color-coral-d20: #c93854;
  --color-coral-d25: #bb344d;
  --color-coral-l10: #ff7a93;
  --color-coral-o00: rgba(255 97 126 / 0%);
  --color-coral-o15: rgba(255 97 126 / 15%);
  --color-coral-o20: rgba(255 97 126 / 20%);
  --color-coral-o30: rgba(255 97 126 / 30%);
  --color-coral-o40: rgba(255 97 126 / 40%);
  --color-coral-o50: rgba(255 97 126 / 50%);
  --color-coral-o60: rgba(255 97 126 / 60%);
  --color-cyan: #009fdf;
  --color-cyan-d05: #0096d2;
  --color-cyan-d05-o30: rgb(0 150 210 / 30%);
  --color-cyan-d20: #0076a6;
  --color-cyan-d20-o30: rgb(0 118 166 / 30%);
  --color-cyan-d25: #006e9a;
  --color-cyan-l40: #66c5ec;
  --color-cyan-l60: #99d9f2;
  --color-light-cyan: #1F4778;
  --color-cyan-l60-o0: rgba(153 217 242 / 0%);
  --color-cyan-l60-o15: rgba(153 217 242 / 15%);
  --color-cyan-l60-o20: rgba(153 217 242 / 20%);
  --color-cyan-l60-o30: rgba(153 217 242 / 30%);
  --color-cyan-l60-o40: rgba(153 217 242 / 40%);
  --color-cyan-l60-o50: rgba(153 217 242 / 50%);
  --color-cyan-l60-o60: rgba(153 217 242 / 60%);
  --color-cyan-l60-o61: rgba(153 217 242 / 61%);
  --color-cyan-l70: #b6e7fa;
  --color-cyan-l80: #ccecf9;
  --color-cyan-o00: rgba(0 159 223 / 0%);
  --color-cyan-o15: rgba(0 159 223 / 15%);
  --color-cyan-o20: rgba(0 159 223 / 20%);
  --color-cyan-o30: rgba(0 159 223 / 30%);
  --color-cyan-o40: rgba(0 159 223 / 40%);
  --color-cyan-o50: rgba(0 159 223 / 50%);
  --color-cyan-o60: rgba(0 159 223 / 60%);
  --color-traffic-green: #52cc7b;
  --color-traffic-red: #d9576f;
  --color-traffic-yellow: #f2c248;
  --color-transparent: transparent;
  --color-white: #fff;
  --color-white-o00: rgba(255 255 255 / 0%);
  --color-white-o15: rgba(255 255 255 / 15%);
  --color-white-o30: rgba(255 255 255 / 30%);
  --color-white-o40: rgba(255 255 255 / 40%);
  --color-white-o50: rgba(255 255 255 / 50%);
  --color-white-o60: rgba(255 255 255 / 60%);
  --color-white-o90: rgba(255 255 255 / 90%);

  // Custom colors
  --color-green: #005b1f;
  --color-green-light: #52cc7b;
  --color-red: #d10d00;
  --color-red-light: #ffa39d;
`;

import styled from "styled-components";
import { List } from "@mui/material";
import { styleBodyM, styleLabelS } from "style/components/Typography";

export const StyledList = styled(List)<StyledListProps>`
  &.MuiList-root {
    ${styleBodyM}
    padding-top: ${({ $disablePadding }) =>
      $disablePadding ? "0rem" : "0.5rem"};
    padding-bottom: ${({ $disablePadding }) =>
      $disablePadding ? "0rem" : "0.5rem"};

    &.MuiTypography-body1 {
      ${styleLabelS}
    }
  }
`;

interface StyledListProps {
  $disablePadding?: boolean;
}
import React, { useEffect, ReactElement } from "react";
import StockChartProps from "./StockChartProps";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import {
  DefaultStockChartsOptions,
  LocalizationEnglish,
  LocalizationSwedish,
  HighChartsTheme,
} from "utils/settings";
import { merge } from "lodash";
import Section from "components/Section";
import Skeleton from "components/Skeleton";

const StockChart = ({
  customOptions,
  culture,
  loading,
  contentId,
  className,
  anchorName,
}: StockChartProps): ReactElement => {
  switch (culture) {
    case "en-US":
      HighChartsTheme.lang = LocalizationEnglish;
      break;
    case "sv-SE":
    default:
      HighChartsTheme.lang = LocalizationSwedish;
      break;
  }

  Highcharts.setOptions(HighChartsTheme);
  const options = merge({}, DefaultStockChartsOptions(customOptions.series ?? []), customOptions);

  return (
    <Section
      className={className}
      id={contentId || anchorName || "chart-container"}
      style={{ display: "grid" }}
    >
      {loading ? (
        <Skeleton variant="rectangular" width="100%" height="400px" />
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={"stockChart"}
          options={options}
        />
      )}
    </Section>
  );
};
export default React.memo(StockChart);

import React, { ReactElement } from "react";

import ListItemIconProps from "./ListItemIconProps";
import { StyledListItemIcon } from "./ListItemIcon.styled";

const ListItemIcon = ({
  children,
  ...restProps
}: ListItemIconProps): ReactElement => {
  return <StyledListItemIcon {...restProps}>{children}</StyledListItemIcon>;
};

export default React.memo(ListItemIcon);

import { useState } from "react";
import TopMenu from "./TopMenu/TopMenu";
import LeftMenu from "./LeftMenu/LeftMenu";
import MenuProps from "./MenuProps";

const Menu = ({ children }: MenuProps) => {
  const [open, setOpen] = useState(false);

  const changeMenu = (newState: boolean) => {
    setOpen(newState);
  };

  return (
    <div style={{ display: "flex" }}>   
      <LeftMenu
        isMobile={false}
        open={open}
        onClick={() => changeMenu(false)}
      />
      <TopMenu
        isMobile={false}
        onMenuClick={() => changeMenu(true)}
      />
      <div id="maincontent" style={{ display: "block", width: "100%" }}>
        {children}
      </div>
    </div>
  );
};

export default Menu;

import * as React from "react";
const Check28 = ({
  customStyle = {
    verticalAlign: "middle",
  },
  width = "28",
  height = "28",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 28 28"
    style={customStyle}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.807 4.105a.5.5 0 0 1 .088.702l-14 18a.5.5 0 0 1-.749.047l-6-6a.5.5 0 1 1 .708-.707l5.6 5.599L24.104 4.193a.5.5 0 0 1 .702-.088"
      clipRule="evenodd"
    />
  </svg>
);
export default Check28;

import Icon from "components/Icon";
import Section from "components/Section";
import React, { ReactElement } from "react";

import {
  BottomContainer,
  BottomInnerContainer,
  BottomLink,
  BottomLinks,
  BottomSection,
  Container,
  FooterWrapper,
  LinkItem,
  Links,
  LinksHeading,
  Logo,
  LogoWrapper,
  SocialLink,
  SocialLinks,
} from "./Footer.styled";
import FooterProps from "./FooterProps";

const Footer = ({
  key = "footerContent",
  logoTitle,
  navigation,
  socialLinks,
  bottomLinks,
  columnsLimit = 2,
  showTopDivider = false,
  hideLogo = false,
  contentId,
  className,
  anchorName,
}: FooterProps): ReactElement => {
  return (
    <FooterWrapper
      $showTopDivider={showTopDivider}
      role="contentinfo"
      className={className}
      id={contentId || anchorName}
    >
      <Section key={key} theme="blue" alignItems="center">
        <Container>
          {navigation?.items?.slice(0, columnsLimit).map((navCol, index) => (
            <Links key={`FooterCol-${index}`}>
              <LinksHeading>{navCol.displayName}</LinksHeading>
              {navCol.items?.map((navItem, index) => (
                <div key={`FooterLink-${index}`}>
                  <LinkItem
                    url={navItem.url}
                    onClick={navItem.onClick}
                    title={
                      (navItem.properties?.title as string) ||
                      navItem.displayName ||
                      ""
                    }
                    target={navItem.target || "_self"}
                    underline="none"
                  >
                    {navItem.displayName}
                  </LinkItem>
                </div>
              ))}
            </Links>
          ))}
          {socialLinks && (
            <SocialLinks key={`SocialLinks`}>
              {socialLinks.items?.map((navItem, index) => (
                <SocialLink
                  key={`FooterLink-${index}`}
                  url={navItem.url}
                  onClick={navItem.onClick}
                  title={
                    (navItem.properties?.title as string) ||
                    navItem.displayName ||
                    ""
                  }
                  target={navItem.target || "_self"}
                  underline="none"
                >
                  <Icon icon={navItem.properties?.icon as string} />
                </SocialLink>
              ))}
            </SocialLinks>
          )}
          {!hideLogo && (
            <LogoWrapper>
              <Logo key={`Logo-logoSmallSolid56`} aria-label={logoTitle} />
            </LogoWrapper>
          )}
        </Container>
        {bottomLinks && (
          <BottomSection>
            <BottomContainer>
              <BottomInnerContainer>
                <BottomLinks key={`BottomLinks`}>
                  {bottomLinks.items?.map((navItem, index) => (
                    <div key={`BottomLink-${index}`}>
                      <BottomLink
                        url={navItem.url}
                        onClick={navItem.onClick}
                        title={
                          (navItem.properties?.title as string) ||
                          navItem.displayName ||
                          ""
                        }
                        target={navItem.target || "_self"}
                        hoverSpan={true}
                        underline="none"
                      >
                        <span>{navItem.displayName}</span>
                      </BottomLink>
                    </div>
                  ))}
                </BottomLinks>
              </BottomInnerContainer>
            </BottomContainer>
          </BottomSection>
        )}
      </Section>
    </FooterWrapper>
  );
};

export default React.memo(Footer);

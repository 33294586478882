import * as React from "react";
const Wealth28 = ({
  customStyle = {
    verticalAlign: "middle",
  },
  width = "28",
  height = "28",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 28 28"
    style={customStyle}
    {...props}
  >
    <mask
      id="wealth28_svg__a"
      width={17}
      height={26}
      x={1.667}
      y={1.133}
      fill="currentColor"
      maskUnits="userSpaceOnUse"
    >
      <path fill="#fff" d="M1.667 1.133h17v26h-17z" />
      <path
        fillRule="evenodd"
        d="M6.533 2.4a2.533 2.533 0 1 0 0 5.067 2.533 2.533 0 0 0 0-5.067m-2.8 2.533a2.8 2.8 0 1 1 5.6 0 2.8 2.8 0 0 1-5.6 0m5.6 5.467h8.4a.133.133 0 0 0 0-.267h-12.8A2.267 2.267 0 0 0 2.667 12.4v13.333a.133.133 0 0 0 .266 0V12.4a2 2 0 0 1 2-2h4.134v15.333a.133.133 0 0 0 .266 0z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.533 2.4a2.533 2.533 0 1 0 0 5.067 2.533 2.533 0 0 0 0-5.067m-2.8 2.533a2.8 2.8 0 1 1 5.6 0 2.8 2.8 0 0 1-5.6 0m5.6 5.467h8.4a.133.133 0 0 0 0-.267h-12.8A2.267 2.267 0 0 0 2.667 12.4v13.333a.133.133 0 0 0 .266 0V12.4a2 2 0 0 1 2-2h4.134v15.333a.133.133 0 0 0 .266 0z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M9.333 10.4v-.35a.35.35 0 0 0-.35.35zm-6.002.397-.248-.247zm.188.189-.247-.248zm5.548-.586h.35a.35.35 0 0 0-.35-.35zM4.35 4.933c0-1.206.978-2.183 2.183-2.183v-.7A2.883 2.883 0 0 0 3.65 4.933zm2.183 2.184A2.183 2.183 0 0 1 4.35 4.933h-.7a2.883 2.883 0 0 0 2.883 2.884zm2.184-2.184a2.183 2.183 0 0 1-2.184 2.184v.7a2.883 2.883 0 0 0 2.884-2.884zM6.533 2.75c1.206 0 2.184.977 2.184 2.183h.7A2.883 2.883 0 0 0 6.533 2.05zm0-.967a3.15 3.15 0 0 0-3.15 3.15h.7a2.45 2.45 0 0 1 2.45-2.45zm3.15 3.15a3.15 3.15 0 0 0-3.15-3.15v.7a2.45 2.45 0 0 1 2.45 2.45zm-3.15 3.15a3.15 3.15 0 0 0 3.15-3.15h-.7a2.45 2.45 0 0 1-2.45 2.45zm-3.15-3.15a3.15 3.15 0 0 0 3.15 3.15v-.7a2.45 2.45 0 0 1-2.45-2.45zm14.35 5.117h-8.4v.7h8.4zm-.216.217c0-.12.097-.217.216-.217v.7a.483.483 0 0 0 .484-.483zm.216.216a.217.217 0 0 1-.216-.216h.7a.483.483 0 0 0-.484-.484zm-8.533 0h8.533v-.7H9.2zm-4.267 0H9.2v-.7H4.933zm-1.355.562c.36-.36.847-.562 1.355-.562v-.7c-.694 0-1.36.276-1.85.767zM3.017 12.4c0-.508.202-.996.561-1.355l-.495-.495a2.62 2.62 0 0 0-.766 1.85zm0 13.333V12.4h-.7v13.333zm-.217-.216c.12 0 .217.097.217.216h-.7c0 .267.216.484.483.484zm-.217.216c0-.12.097-.216.217-.216v.7a.483.483 0 0 0 .483-.484zm0-13.333v13.333h.7V12.4zm.689-1.662a2.35 2.35 0 0 0-.689 1.662h.7c0-.438.174-.857.484-1.167zm1.661-.688a2.35 2.35 0 0 0-1.661.688l.495.495c.31-.31.729-.483 1.166-.483zm4.134 0H4.933v.7h4.134zm-.35.35v15.333h.7V10.4zm0 15.333c0 .267.216.484.483.484v-.7c.12 0 .217.097.217.216zm.483.484a.483.483 0 0 0 .483-.484h-.7c0-.12.097-.216.217-.216zm.483-.484V10.4h-.7v15.333z"
      mask="url(#wealth28_svg__a)"
    />
    <mask
      id="wealth28_svg__b"
      width={15}
      height={26}
      x={11.267}
      y={1.133}
      fill="currentColor"
      maskUnits="userSpaceOnUse"
    >
      <path fill="#fff" d="M11.267 1.133h15v26h-15z" />
      <path
        fillRule="evenodd"
        d="M15.2 2.267a.133.133 0 0 0-.267 0v2H12.4a.133.133 0 0 0 0 .266h12.666v13.6H12.4a.133.133 0 0 0 0 .267h6.312l3.166 7.386a.133.133 0 0 0 .245-.105l-3.12-7.281H25.2c.074 0 .133-.06.133-.133V4.4a.133.133 0 0 0-.133-.133h-10z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="var(--color-coral)"
      fillRule="evenodd"
      d="M15.2 2.267a.133.133 0 0 0-.267 0v2H12.4a.133.133 0 0 0 0 .266h12.666v13.6H12.4a.133.133 0 0 0 0 .267h6.312l3.166 7.386a.133.133 0 0 0 .245-.105l-3.12-7.281H25.2c.074 0 .133-.06.133-.133V4.4a.133.133 0 0 0-.133-.133h-10z"
      clipRule="evenodd"
    />
    <path
      fill="var(--color-coral)"
      d="M14.933 4.267v.35a.35.35 0 0 0 .35-.35zm10.134.266h.35a.35.35 0 0 0-.35-.35zm0 13.6v.35a.35.35 0 0 0 .35-.35zm-6.355.267.322-.138a.35.35 0 0 0-.322-.212zm3.166 7.386.321-.138zm.175.07.137.322zm.07-.175.321-.138zm-3.12-7.281v-.35a.35.35 0 0 0-.323.488zM15.2 4.267h-.35c0 .193.157.35.35.35zm-.133-1.784a.217.217 0 0 1-.217-.216h.7a.483.483 0 0 0-.483-.484zm.216-.216c0 .12-.097.216-.216.216v-.7a.483.483 0 0 0-.484.484zm0 2v-2h-.7v2zm-.35-.35H12.4v.7h2.533zm-2.533 0a.483.483 0 0 0-.483.483h.7c0 .12-.097.217-.217.217zm-.483.483c0 .267.216.483.483.483v-.7c.12 0 .217.097.217.217zm.483.483h2.666v-.7H12.4zm2.667-.7v.7zm0 0v.7zm0 .7h10v-.7h-10zm9.65-.35v13.6h.7v-13.6zm.35 13.25H12.4v.7h12.667zm-12.667 0a.483.483 0 0 0-.483.484h.7c0 .12-.097.216-.217.216zm-.483.484c0 .267.216.483.483.483v-.7c.12 0 .217.097.217.217zm.483.483h6.312v-.7H12.4zm5.99-.212 3.166 7.386.643-.276-3.165-7.386zm3.166 7.386c.105.245.39.359.634.254l-.275-.644a.217.217 0 0 1 .284.114zm.634.254a.483.483 0 0 0 .254-.635l-.643.276a.217.217 0 0 1 .114-.285zm.254-.635-3.12-7.28-.644.275 3.12 7.28zm-3.442-6.793H25.2v-.7h-6.198zm6.198 0a.483.483 0 0 0 .483-.483h-.7c0-.12.097-.217.217-.217zm.483-.483V4.4h-.7v13.867zm0-13.867a.483.483 0 0 0-.483-.483v.7a.217.217 0 0 1-.217-.217zm-.483-.483h-10v.7h10zm-10.35-1.65v2h.7v-2z"
      mask="url(#wealth28_svg__b)"
    />
  </svg>
);
export default Wealth28;

import React, { ReactElement } from "react";

import TypographyProperties from "./TypographyProps";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import useMaterialUIBaseTheme from "hooks/useMaterialUIBaseTheme";
import { StyledTypography } from "./Typography.styled";

const Typography = ({ ...restProps }: TypographyProperties): ReactElement => {
  return (
    <MuiThemeProvider theme={useMaterialUIBaseTheme()}>
      <StyledTypography {...restProps}>{restProps.children}</StyledTypography>
    </MuiThemeProvider>
  );
};

export default React.memo(Typography);

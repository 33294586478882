import React, { ReactElement } from "react";

import ListItemProps from "./ListItemProps";
import { StyledListItem } from "./ListItem.styled";

const ListItem = ({ children, ...restProps }: ListItemProps): ReactElement => {
  return <StyledListItem {...restProps}>{children}</StyledListItem>;
};

export default React.memo(ListItem);

import React, { ReactElement, useContext } from "react";
import CountUp from "react-countup";

import { ColoredNumber } from "./SignColorNumber.styled";
import SignColorNumberProps from "./SignColorNumberProps";
import { LocalizationEnglish, LocalizationSwedish } from "utils/settings";
import { ThemeContext, ThemeProvider } from "styled-components";

const SignColorNumber = ({
  number,
  start = 0,
  delay = 0,
  suffix = "%",
  prefix,
  biggerSize = "M",
  animated = true,
  culture,
  decimals = 2,
  theme,
  className,
}: SignColorNumberProps): ReactElement => {
  const decimalSeparator =
    culture === "en-US"
      ? LocalizationEnglish.decimalPoint
      : LocalizationSwedish.decimalPoint;
  const thousandsSeparator =
    culture === "en-US"
      ? LocalizationEnglish.thousandsSep
      : LocalizationSwedish.thousandsSep;
  const exitingTheme = useContext(ThemeContext) || {};

  return (
    <ThemeProvider
      theme={{
        theme: theme || exitingTheme["theme"],
      }}
    >
      {animated ? (
        <CountUp
          decimal={decimalSeparator}
          separator={thousandsSeparator}
          duration={1}
          start={start}
          decimals={decimals}
          end={number || 0}
          enableScrollSpy={true}
          scrollSpyDelay={delay}
          scrollSpyOnce={true}
          delay={delay}
          suffix={suffix}
          prefix={prefix || ""}
          className={className}
        >
          {({ countUpRef }) => (
            <ColoredNumber
              size={biggerSize}
              positive={number >= 0}
              ref={countUpRef}
            >
              {start}
            </ColoredNumber>
          )}
        </CountUp>
      ) : (
        <CountUp
          decimal={decimalSeparator}
          separator={thousandsSeparator}
          start={number}
          decimals={decimals}
          end={number || 0}
          suffix={suffix}
          prefix={prefix || ""}
          className={className}
        >
          {({ countUpRef }) => (
            <ColoredNumber
              size={biggerSize}
              positive={number >= 0}
              ref={countUpRef}
            >
              {start}
            </ColoredNumber>
          )}
        </CountUp>
      )}
    </ThemeProvider>
  );
};

export default React.memo(SignColorNumber);

import {
  Benefits28,
  Button,
  Divider,
  Document28,
  Drawer,
  Home28,
  Insurance28,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  LogoSmallDefault42,
  LogoDefault56,
  Wealth28,
  Pension28,
  Hr28,
  ListItemButton,
} from "ui-library";
import LeftMenuProps from "./LeftMenuProps";
import { MenuContainer, StyledSkipLink, Spacer } from "./LeftMenu.styled";
import LanguageChooser from "../LanguageChooser/LanguageChooser";

const LeftMenu = ({ isMobile = false, open, onClick }: LeftMenuProps) => {
  return (
    <Drawer
      anchor={isMobile ? "top" : "left"}
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: isMobile ? "100% !important" : "268px !important",
          height: "100%",
        },
        width: isMobile ? "100% !important" : "268px !important",
        height: "100%",
        zIndex: 3,
      }}
      theme="lightgray"
      variant={isMobile ? "temporary" : "permanent"}
      transitionDuration={0}
      open={open}
    >
      <StyledSkipLink href="#maincontent">SkipMain</StyledSkipLink>
      <MenuContainer $isMobile={isMobile} href="/" ariaLabel="HomeMenu">
        {isMobile && <LogoSmallDefault42 aria-hidden="true" />}
        {!isMobile && <LogoDefault56 aria-hidden="true" />}
      </MenuContainer>
      {isMobile && (
        <Button
          actionTheme="black"
          icon="close28"
          iconOnly
          onClick={onClick}
          size="small"
          style={{
            position: "absolute",
            right: "1rem",
            top: "1rem",
          }}
          variant="outline"
        />
      )}
      <List component="nav">
        <ListItem disablePadding>
          <ListItemButton component="a" href="#" aria-label="HomeMenu">
            <ListItemIcon>
              <Home28 />
            </ListItemIcon>
            <ListItemText primary="HomeMenu" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider variant="middle" />
      <List component="nav">
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            href="#pensionBaseUrl/MeetingDocuments/MyDocuments"
            aria-label="DocumentsMenu"
          >
            <ListItemIcon>
              <Document28 />
            </ListItemIcon>
            <ListItemText primary="DocumentsMenu" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider variant="middle" />
      <List component="nav">
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            selected
            href="#wealthBaseUrl"
            aria-label="WealthMenu"
          >
            <ListItemIcon>
              <Wealth28 />
            </ListItemIcon>
            <ListItemText primary="WealthMenu" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider variant="middle" />
      <List component="nav">
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            href="#pensionBaseUrl"
            aria-label="PensionMenu"
          >
            <ListItemIcon>
              <Pension28 />
            </ListItemIcon>
            <ListItemText primary="PensionMenu" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider variant="middle" />
      <List component="nav">
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            href="#privateInsuracesBaseUrl"
            aria-label="PrivateInsurancesMenu"
          >
            <ListItemIcon>
              <Insurance28 />
            </ListItemIcon>
            <ListItemText
              primary="PrivateInsurancesMenu"
            />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider variant="middle" />
      <List component="nav">
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            href="#payrollBaseUrl"
            aria-label="PayrollMenu"
          >
            <ListItemIcon>
              <Hr28 />
            </ListItemIcon>
            <ListItemText primary="PayrollMenu" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider variant="middle" />
      <List component="nav">
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            href="#benefitsBaseUrl"
            aria-label="BenefitsMenu"
          >
            <ListItemIcon>
              <Benefits28 />
            </ListItemIcon>
            <ListItemText primary="BenefitsMenu" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider variant="middle" />
      <Spacer></Spacer>
      <LanguageChooser />
    </Drawer>
  );
};

export default LeftMenu;

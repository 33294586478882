import { Link } from "ui-library";
import styled from "styled-components";

export const MenuContainer = styled(Link)<{ $isMobile: boolean }>`
  && {
    color: currentColor;
    width: fit-content;
    ${(props) =>
      props.$isMobile ? "padding: 0.875rem 1rem;" : "padding: 1rem;"}
    :focus-visible {
      outline-offset: -10px;
    }
  }
`;

export const StyledSkipLink = styled(Link)`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  :focus-visible {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
    font-size: 1.5rem;
    margin: 1rem;
  }
`;

export const Spacer = styled.div`
  flex: 1;
`;

import React, { ReactElement, useContext } from "react";
import { ThemeContext, ThemeProvider } from "styled-components";

import PageCard from "components/PageCard";

import {
  CardWrapper,
  ContentContainer,
  PageCardItems,
  TitleHeading,
} from "./PageCardSection.styled";
import PageCardSectionProps from "./PageCardSectionProps";

const PageCardSection = ({
  id,
  theme,
  contentTheme,
  title,
  pageCardList,
  contentId,
  className,
  anchorName,
}: PageCardSectionProps): ReactElement => {
  const exitingTheme = useContext(ThemeContext) || {};
  return (
    <ThemeProvider
      theme={{
        theme: theme || exitingTheme["theme"],
        contentTheme: contentTheme || exitingTheme["contentTheme"],
      }}
    >
      {pageCardList !== undefined && pageCardList.length > 0 && (
        <ContentContainer
          className={className}
          id={id || contentId || anchorName}
        >
          {title && <TitleHeading>{title}</TitleHeading>}
          <PageCardItems>
            {pageCardList?.map((cardData, index) => (
              <CardWrapper key={`PageCard${index}`}>
                <PageCard {...cardData} size="small" />
              </CardWrapper>
            ))}
          </PageCardItems>
        </ContentContainer>
      )}
    </ThemeProvider>
  );
};

export default React.memo(PageCardSection);

import React, { ReactElement } from "react";

import DrawerProperties from "./DrawerProps";
import { StyledDrawer } from "./Drawer.styled";
import { ThemeProvider } from "styled-components";

const Drawer = ({
  theme = "white",
  size,
  ...restProps
}: DrawerProperties): ReactElement => {
  return (
    <ThemeProvider theme={{ theme: theme }}>
      <StyledDrawer {...restProps} $size={size} $anchor={restProps.anchor}>{restProps.children}</StyledDrawer>
    </ThemeProvider>
  );
};

export default React.memo(Drawer);

import * as React from "react";
const Document28 = ({
  customStyle = {
    verticalAlign: "middle",
  },
  width = "28",
  height = "28",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 28 28"
    style={customStyle}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24 3H5v23h19zM6 25V4h17v21zM20 9H9v1h11zM9 14h11v1H9zm5 5H9v1h5z"
      clipRule="evenodd"
    />
  </svg>
);
export default Document28;

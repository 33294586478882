import {
  StyledContainer,
  RightSection,
  UserMenuWrapper,
  LeftSection,
  MenuButton,
  LogoButton,
} from "./TopMenu.styled";
import React, { useEffect } from "react";
import TopMenuProps from "./TopMenuProps";

const TopMenu = ({ isMobile = false, onMenuClick }: TopMenuProps) => {
  const [hover, setHover] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const onMouseEnter = () => {
    setHover(true);
  };
  const onMouseLeave = () => {
    setHover(false);
  };

  const onFocus = () => {
    setHover(true);
  };

  const onBlur = () => {
    setHover(false);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      (ref.current?.firstChild?.firstChild as HTMLAnchorElement)?.click();
    }
  };

  const ref = React.useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const handleClick = (event: any) => {
      if (ref.current && ref.current.firstChild?.childNodes.length === 2) {
        const aElements = Array.from(
          (
            ref.current.firstChild?.lastChild as HTMLDivElement
          ).querySelectorAll("a")
        );
        aElements.forEach((a) => {
          a.tabIndex = 0;
          a.addEventListener("keydown", (event) => {
            if (event.key === "Enter" || event.key === " ") {
              a.click();
            }
          });
        });
        setOpen(true);
      } else {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <StyledContainer isMobile={isMobile} hover={hover || open}>
        <LeftSection>{isMobile && <LogoButton />}</LeftSection>
        <RightSection>
          <UserMenuWrapper
            ref={ref}
            isMobile={isMobile}
            hover={hover || open}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            aria-label="User Menu"
            alt-text="User Menu"
          />
          {isMobile && (
            <MenuButton onClick={onMenuClick} aria-label="Open Menu" />
          )}
        </RightSection>
      </StyledContainer>
    </div>
  );
};

export default TopMenu;

import React, { ReactElement } from "react";

import ListItemButtonProps from "./ListItemButtonProps";
import { StyledListItemButton } from "./ListItemButton.styled";
import { ListItemButton as MuiListItemButton } from "@mui/material";

const ListItemButton = ({
  children,
  ...restProps
}: ListItemButtonProps): ReactElement => {
  return <StyledListItemButton {...restProps}>{children}</StyledListItemButton>;
};

export default React.memo(ListItemButton) as typeof MuiListItemButton;

import React, { ReactElement } from "react";

import ListItemTextProps from "./ListItemTextProps";
import { StyledListItemText } from "./ListItemText.styled";

const ListItemText = ({
  children,
  ...restProps
}: ListItemTextProps): ReactElement => {
  return (
    <StyledListItemText {...restProps} $inset={restProps.inset}>
      {children}
    </StyledListItemText>
  );
};

export default React.memo(ListItemText);

import React, { ReactElement, useContext } from "react";
import { ThemeContext, ThemeProvider } from "styled-components";

import Image from "components/Image";
import { useBreakpoint } from "hooks";
import { BREAKPOINT_NUMBER } from "style/breakpoints";
import generateSrcSet from "style/generateSrcSet";

import {
  Container,
  Content,
  Heading,
  ImageWrapper,
  Introduction,
  Link,
  StyledWatermark,
  ContentContainer,
} from "./PageCard.styled";
import PageCardProps from "./PageCardProps";

const PageCard = ({
  heading,
  image,
  introduction,
  watermark,
  url,
  target = "",
  size = "small",
  contentId,
  className,
  anchorName,
  theme,
  contentTheme,
}: PageCardProps): ReactElement => {
  const exitingTheme = useContext(ThemeContext) || {};
  if (image?.caption) delete image.caption;
  if (image && image.url)
    switch (size) {
      case "large":
        image.srcSet = generateSrcSet({
          image: image.url,
          focalPoint: image.focalPoint,
          content: [
            {
              width: 1365,
              height: Math.ceil(1365 / 1.5),
              breakpoint: BREAKPOINT_NUMBER.XL,
            },
            {
              width: 992,
              height: Math.ceil(992 / 1.5),
              breakpoint: BREAKPOINT_NUMBER.L,
            },
            {
              width: 992,
              height: Math.ceil(992 / 1.5),
              breakpoint: BREAKPOINT_NUMBER.M,
            },
            {
              width: 768,
              height: Math.ceil(768 / 1.5),
            },
          ],
          maxWidth: image.uploadedWidth,
          maxHeight: image.uploadedHeight,
          format: "jpg",
        });
        break;
      case "medium":
        image.srcSet = generateSrcSet({
          image: image.url,
          focalPoint: image.focalPoint,
          content: [
            {
              width: 900,
              height: Math.ceil(900 / 1.5),
              breakpoint: BREAKPOINT_NUMBER.XL,
            },
            {
              width: 600,
              height: Math.ceil(600 / 1.5),
              breakpoint: BREAKPOINT_NUMBER.L,
            },
            {
              width: 500,
              height: Math.ceil(500 / 1.5),
              breakpoint: BREAKPOINT_NUMBER.M,
            },
            {
              width: 768,
              height: Math.ceil(768 / 1.5),
            },
          ],
          maxWidth: image.uploadedWidth,
          maxHeight: image.uploadedHeight,
          format: "jpg",
        });
        break;
      default:
        image.srcSet = generateSrcSet({
          image: image.url,
          focalPoint: image.focalPoint,
          content: [
            {
              width: 650,
              height: Math.ceil(650 / 1.5),
              breakpoint: BREAKPOINT_NUMBER.XL,
            },
            {
              width: 500,
              height: Math.ceil(500 / 1.5),
              breakpoint: BREAKPOINT_NUMBER.M,
            },
            {
              width: 768,
              height: Math.ceil(768 / 1.5),
            },
          ],
          maxWidth: image.uploadedWidth,
          maxHeight: image.uploadedHeight,
          format: "jpg",
        });
        break;
    }

  let iconSize: string;
  switch (useBreakpoint()) {
    case "XL":
    case "XXXL":
      iconSize = size === "small" ? "105" : "140";
      break;
    case "XXXXL":
      iconSize = "140";
      break;
    case "L":
      iconSize = "105";
      break;
    case "M":
      iconSize = "105";
      break;
    default:
      iconSize = "56";
  }

  const isSafari =
    typeof navigator !== "undefined" &&
    navigator.userAgent.indexOf("Safari") !== -1 &&
    navigator.userAgent.indexOf("Chrome") === -1;

  function PageCardContent() {
    return (
      <>
        <ImageWrapper>
          {image?.url && <Image {...image} />}
          {watermark && (
            <StyledWatermark
              key={`Watermark-${watermark}-${iconSize || ""}`}
              icon={`${watermark}${iconSize}`}
              variant={image?.url ? "white" : "color"}
            />
          )}
        </ImageWrapper>
        <Content>
          {heading && (
            <Heading>
              <span
                dangerouslySetInnerHTML={{
                  __html: heading,
                }}
              />
            </Heading>
          )}

          {introduction && <Introduction>{introduction}</Introduction>}
        </Content>
      </>
    );
  }

  return (
    <ThemeProvider
      theme={{
        size: size,
        showImage: !!image?.url,
        isSafari: isSafari,
        theme: theme || exitingTheme["theme"],
        contentTheme: contentTheme || exitingTheme["contentTheme"],
      }}
    >
      <Container className={className} id={contentId || anchorName}>
        {url ? (
          <Link to={url} title={heading} target={target} underline="none">
            <PageCardContent />
          </Link>
        ) : (
          <ContentContainer>
            <PageCardContent />
          </ContentContainer>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default React.memo(PageCard);

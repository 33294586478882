import styled from "styled-components";
import { ListItemText } from "@mui/material";
import { styleBodyM, styleLabelS } from "style/components/Typography";

export const StyledListItemText = styled(ListItemText)<StyledListItemTextProps>`
  &.MuiListItemText-root {
    padding-left: ${({ $inset }) => ($inset ? "36px" : "0px")};
    margin: 0;

    .MuiTypography-root {
      ${styleBodyM}

      &.MuiTypography-body1 {
        ${styleLabelS}
      }
    }
  }
`;

interface StyledListItemTextProps {
  $inset?: boolean;
}
